import React from "react";
import "./SwitchCheckbox.css";

type SwitchCheckboxProps = {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
};


export function SwitchCheckbox(props : SwitchCheckboxProps) {
  const { checked, onChange, className, disabled } = props;
  return (
    <div className={className}>
      <label className="switch">
        <input
          disabled={disabled}
          type="checkbox"
          role="button"
          aria-expanded="false"
          checked={checked}
          onChange={onChange}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
