import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FaAddressCard,
  FaFileAlt,
  FaSave,
  FaSpinner,
  FaTimes,
} from "react-icons/fa";
import { ImDisplay } from "react-icons/im";
import { COLORS } from "../../assets/colors";
import IconCog from "../../assets/icons/cog.svg";
import IconVideo from "../../assets/icons/video.svg";
import IconEdit from "../../assets/icons/edit.svg";
import { BsBorderAll, BsDoorOpenFill } from "react-icons/bs";
import {
  MdDashboardCustomize,
  MdOutlineMasks,
  MdTabletAndroid,
} from "react-icons/md";
import { BiMask } from "react-icons/bi";
import CameraSettingsGeneral from "./CameraSettingsGeneral";
import CameraSettingsAccessControl from "./CameraSettingsAccessControl";
import CameraSettingsAntiFraud from "./CameraSettingsAntiFraud";
import CameraSettingsDisplayAndroid from "./CameraSettingsDisplayAndroid";
import CameraSettingsMask from "./CameraSettingsMask";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { DataContext } from "../../App";
import CameraSettingsLiveView from "./CameraSettingsLiveView";
import { useApiUpdateCamera } from "../../context/camerasContext";
import CameraSettingsTagReader from "./CameraSettingsTagReader";
import CameraSettingsPersonTracking from "./CameraSettingsPersonTracking";
import { RiSignalTowerFill, RiUserLocationFill } from "react-icons/ri";
import { useIsMobile } from "../../utils/hooks";
import { CameraSettingsLogs } from "./CameraSettingsLogs";
import { CameraSettingsBrandingAndroid } from "./CameraSettingsBrandingAndroid";

function CameraSettingsModal(props) {
  const { camera, close } = props;
  const [settingsType, setSettingsType] = useState("general");
  const [editedCamera, setEditedCamera] = useState(camera);
  const [editName, setEditName] = useState(false);
  const { userInfo } = useContext(DataContext);
  const isMobile = useIsMobile();

  const { mutate: updateCamera, isLoading: isSaving } = useApiUpdateCamera();

  useEffect(() => setEditedCamera(camera), [camera]);

  const displayAndroidDefaultsValues = {
    privacy_mode_enabled: true,
    identification_popup_enabled: false,
    show_entrance_button: true,
    show_exit_button: true,
    show_person_description: false,
    show_person_source_image: false,
  };

  const saveCamera = () => {
    var cameraToSend = { ...editedCamera };
    if (!cameraToSend.executer_groups) {
      cameraToSend.executer_groups = [];
    }
    if (!cameraToSend.audio_clips) {
      cameraToSend.audio_clips = {};
    }
    if (!cameraToSend.anti_spoofing) {
      cameraToSend.anti_spoofing = {};
    }
    if (!cameraToSend.mask_verification) {
      cameraToSend.mask_verification = {};
    }
    if (!cameraToSend.rotate_degree) {
      cameraToSend.rotate_degree = 0;
    }
    if (cameraToSend.face_recognition_enabled === null) {
      cameraToSend.face_recognition_enabled = true;
    }
    if (!cameraToSend.display_android) {
      cameraToSend.display_android = displayAndroidDefaultsValues;
    }
    for (let key in displayAndroidDefaultsValues) {
      if (cameraToSend.display_android[key] === undefined) {
        cameraToSend.display_android[key] = displayAndroidDefaultsValues[key];
      }
    }
    if (cameraToSend.branding_settings?.background_image_data) {
      cameraToSend.branding_settings.background_image_data =
        cameraToSend.branding_settings?.background_image_data.split(
          "base64,"
        )[1];
    }

    cameraToSend.is_file =
      !!cameraToSend.url && !cameraToSend.url.startsWith("rtsp://");

    cameraToSend.additional_controllers = cameraToSend.additional_controllers
      ? cameraToSend.additional_controllers.filter(
          (c) => c.groups && c.groups.length > 0
        )
      : [];

    updateCamera({
      cameraId: camera.id,
      cameraData: cameraToSend,
      onSuccessCallback: () =>
        showSuccessDialog("Camera updated successfully").then(close),
      onErrorCallback: (resp) =>
        showErrorDialog(resp.message || "Failed to update camera"),
    });
  };

  return (
    <div
      className="h-100"
      style={{
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <div
        className="d-flex p-3 px-2 align-items-center justify-content-between"
        style={{
          backgroundColor: COLORS.Gray800,
          borderRadius: "8px 8px 0 0",
        }}
      >
        <div
          className="d-flex"
          style={{
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          <div className="d-flex align-items-center">
            <img
              src={IconCog}
              height={"20"}
              width={"20"}
              alt="cog-icon"
              className="me-2"
            />
            <div className="d-flex ">Camera's Settings</div>
          </div>
        </div>
        <div className="d-flex pointer pe-3" onClick={close}>
          <FaTimes className="fs-3" />
        </div>
      </div>
      <div className="">
        <div className="d-flex justify-content-between align-items-center px-4 pt-4 pb-2">
          <div
            className="d-flex  gap-16 align-items-center"
            style={{
              fontSize: "24px",
              height: "32px",
            }}
          >
            {camera.is_android ? (
              <MdTabletAndroid />
            ) : (
              <img
                src={IconVideo}
                height={"20"}
                width={"20"}
                alt="video-icon"
              />
            )}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{editedCamera.name}</Tooltip>}
            >
              {editName ? (
                <input
                  className="sr-text-input"
                  type="text"
                  id="edit-cam-name"
                  value={editedCamera.name}
                  onChange={(e) =>
                    setEditedCamera({
                      ...editedCamera,
                      name: e.target.value,
                    })
                  }
                />
              ) : (
                <span className="text-truncate fw-bold mx-2">
                  {editedCamera.name}
                </span>
              )}
            </OverlayTrigger>
            {editName ? (
              <FaSave
                style={{ cursor: "pointer" }}
                onClick={() => setEditName(false)}
              />
            ) : (
              <img
                src={IconEdit}
                height={"20"}
                width={"20"}
                alt="edit-icon"
                style={{ cursor: "pointer" }}
                onClick={() => setEditName(true)}
              />
            )}
          </div>
          {!isMobile && (
            <button
              className="sr-btn d-flex align-items-center justify-content-center"
              id="save-cam-changes"
              onClick={saveCamera}
              disabled={!userInfo.is_staff}
              style={{
                maxWidth: "300px",
              }}
            >
              {isSaving && <FaSpinner className="me-2 fa-spin" />}
              Save Changes
            </button>
          )}
        </div>

        <div className="d-flex justify-content-between mt-1">
          <div
            className={isMobile ? "" : "pb-5"}
            style={{
              width: isMobile ? "100%" : "30%",
              backgroundColor: COLORS.Gray800,
              borderRadius: "0 0 0 8px",
            }}
          >
            <div className=" w-100">
              <button
                type="button"
                className="sr-dark-btn w-100"
                style={{
                  color:
                    settingsType === "general" ? COLORS.PrimaryLight : "unset",
                }}
                onClick={() => setSettingsType("general")}
              >
                <div className="d-flex  text-start">
                  {<BsBorderAll className="text-start me-3" />}
                  General Settings
                </div>
              </button>
              {isMobile && settingsType === "general" && (
                <div
                  className="w-100 px-4"
                  style={{
                    backgroundColor: COLORS.Gray700,
                  }}
                >
                  <CameraSettingsGeneral
                    setCamera={setEditedCamera}
                    camera={editedCamera}
                  />
                </div>
              )}
              <button
                type="button"
                className="sr-dark-btn w-100"
                style={{
                  color:
                    settingsType === "access-control"
                      ? COLORS.PrimaryLight
                      : "unset",
                }}
                onClick={() => setSettingsType("access-control")}
              >
                <div className="d-flex text-start">
                  {<BsDoorOpenFill className="text-start me-3" />}
                  Access Control
                </div>
              </button>
              {isMobile && settingsType === "access-control" && (
                <div
                  className="w-100 px-4"
                  style={{
                    backgroundColor: COLORS.Gray700,
                  }}
                >
                  <CameraSettingsAccessControl
                    setCamera={setEditedCamera}
                    camera={editedCamera}
                  />
                </div>
              )}
              {!camera.is_android && (
                <>
                  {" "}
                  <button
                    type="button"
                    className="sr-dark-btn w-100"
                    style={{
                      color:
                        settingsType === "live-view"
                          ? COLORS.PrimaryLight
                          : "unset",
                    }}
                    onClick={() => setSettingsType("live-view")}
                  >
                    <div className="d-flex  text-start">
                      <RiSignalTowerFill className="text-start me-3" />
                      Live View
                    </div>
                  </button>
                  {isMobile && settingsType === "live-view" && (
                    <div
                      className="w-100 px-4"
                      style={{
                        backgroundColor: COLORS.Gray700,
                      }}
                    >
                      <CameraSettingsLiveView
                        setCamera={setEditedCamera}
                        camera={editedCamera}
                      />
                    </div>
                  )}
                </>
              )}
              <button
                type="button"
                className="sr-dark-btn w-100"
                style={{
                  color:
                    settingsType === "anti-fraud"
                      ? COLORS.PrimaryLight
                      : "unset",
                }}
                onClick={() => setSettingsType("anti-fraud")}
              >
                <div className="d-flex align-items-start">
                  {<BiMask className="text-start me-3" />}
                  Anti-Fraud
                </div>
              </button>
              {isMobile && settingsType === "anti-fraud" && (
                <div
                  className="w-100 px-4"
                  style={{
                    backgroundColor: COLORS.Gray700,
                  }}
                >
                  <CameraSettingsAntiFraud
                    setCamera={setEditedCamera}
                    camera={editedCamera}
                  />
                </div>
              )}
              <button
                type="button"
                className="sr-dark-btn w-100"
                style={{
                  color:
                    settingsType === "tag-reader"
                      ? COLORS.PrimaryLight
                      : "unset",
                }}
                onClick={() => setSettingsType("tag-reader")}
              >
                <div className="d-flex  text-start">
                  {<FaAddressCard className="text-start me-3" />}
                  Tag Reader
                </div>
              </button>
              {isMobile && settingsType === "tag-reader" && (
                <div
                  className="w-100 px-4"
                  style={{
                    backgroundColor: COLORS.Gray700,
                  }}
                >
                  <CameraSettingsTagReader
                    setCamera={setEditedCamera}
                    camera={editedCamera}
                  />
                </div>
              )}
              {!camera.is_android && (
                <>
                  {" "}
                  <button
                    type="button"
                    className="sr-dark-btn w-100"
                    style={{
                      color:
                        settingsType === "person-tracking"
                          ? COLORS.PrimaryLight
                          : "unset",
                    }}
                    onClick={() => setSettingsType("person-tracking")}
                  >
                    <div className="d-flex  text-start">
                      <RiUserLocationFill className="text-start me-3" />
                      Person Tracking
                    </div>
                  </button>
                  {isMobile && settingsType === "person-tracking" && (
                    <div
                      className="w-100 px-4"
                      style={{
                        backgroundColor: COLORS.Gray700,
                      }}
                    >
                      <CameraSettingsPersonTracking
                        setCamera={setEditedCamera}
                        camera={editedCamera}
                      />
                    </div>
                  )}
                </>
              )}
              {!camera.is_android && (
                <>
                  <button
                    type="button"
                    className="sr-dark-btn w-100"
                    style={{
                      color:
                        settingsType === "mask-verification"
                          ? COLORS.PrimaryLight
                          : "unset",
                    }}
                    onClick={() => setSettingsType("mask-verification")}
                  >
                    <div className="d-flex  text-start">
                      <MdOutlineMasks className="text-start me-3" />
                      Mask Verification
                    </div>
                  </button>
                  {isMobile && settingsType === "mask-verification" && (
                    <div
                      className="w-100 px-4"
                      style={{
                        backgroundColor: COLORS.Gray700,
                      }}
                    >
                      <CameraSettingsMask
                        setCamera={setEditedCamera}
                        camera={editedCamera}
                      />
                    </div>
                  )}
                </>
              )}
              {camera.is_android && (
                <button
                  type="button"
                  className="sr-dark-btn w-100"
                  style={{
                    color:
                      settingsType === "display_android"
                        ? COLORS.PrimaryLight
                        : "unset",
                  }}
                  onClick={() => setSettingsType("display_android")}
                >
                  <div className="d-flex align-items-center">
                    {<ImDisplay className="text-start me-3" />}
                    Display
                  </div>
                </button>
              )}

              {camera.is_android &&
                isMobile &&
                settingsType === "display_android" && (
                  <div
                    className="w-100 px-4"
                    style={{
                      backgroundColor: COLORS.Gray700,
                    }}
                  >
                    <CameraSettingsDisplayAndroid
                      setCamera={setEditedCamera}
                      camera={editedCamera}
                    />
                  </div>
                )}
              {camera.is_android && (
                <button
                  type="button"
                  className="sr-dark-btn w-100"
                  style={{
                    color:
                      settingsType === "branding_android"
                        ? COLORS.PrimaryLight
                        : "unset",
                  }}
                  onClick={() => setSettingsType("branding_android")}
                >
                  <div className="d-flex align-items-center">
                    {<MdDashboardCustomize className="text-start me-3" />}
                    Branding
                  </div>
                </button>
              )}
              {camera.is_android &&
                isMobile &&
                settingsType === "branding_android" && (
                  <div
                    className="w-100 px-4"
                    style={{
                      backgroundColor: COLORS.Gray700,
                    }}
                  >
                    <CameraSettingsBrandingAndroid
                      setCamera={setEditedCamera}
                      camera={editedCamera}
                    />
                  </div>
                )}

              {camera.is_android && (
                <>
                  <button
                    type="button"
                    className="sr-dark-btn w-100"
                    style={{
                      color:
                        settingsType === "logs" ? COLORS.PrimaryLight : "unset",
                    }}
                    onClick={() => setSettingsType("logs")}
                  >
                    <div className="d-flex  text-start">
                      <FaFileAlt className="text-start me-3" />
                      Logs
                    </div>
                  </button>
                  {isMobile && settingsType === "logs" && (
                    <div
                      className="w-100 px-4"
                      style={{
                        backgroundColor: COLORS.Gray700,
                      }}
                    >
                      <CameraSettingsLogs
                        setCamera={setEditedCamera}
                        camera={editedCamera}
                      />
                    </div>
                  )}
                </>
              )}
              {isMobile && (
                <button
                  className="sr-btn d-flex align-items-center justify-content-center w-100"
                  id="save-cam-changes"
                  onClick={saveCamera}
                  disabled={!userInfo.is_staff}
                >
                  {isSaving && <FaSpinner className="me-2 fa-spin" />}
                  Save Changes
                </button>
              )}
            </div>
          </div>
          {!isMobile && (
            <div
              className="px-4"
              style={{
                width: "70%",
              }}
            >
              {settingsType === "general" && (
                <CameraSettingsGeneral
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
              {settingsType === "access-control" && (
                <CameraSettingsAccessControl
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
              {settingsType === "live-view" && (
                <CameraSettingsLiveView
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
              {settingsType === "anti-fraud" && (
                <CameraSettingsAntiFraud
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
              {settingsType === "display_android" && (
                <CameraSettingsDisplayAndroid
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
              {settingsType === "branding_android" && (
                <CameraSettingsBrandingAndroid
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
              {settingsType === "tag-reader" && (
                <CameraSettingsTagReader
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
              {settingsType === "person-tracking" && (
                <CameraSettingsPersonTracking
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
              {settingsType === "mask-verification" && (
                <CameraSettingsMask
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
              {settingsType === "logs" && (
                <CameraSettingsLogs
                  setCamera={setEditedCamera}
                  camera={editedCamera}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CameraSettingsModal;
