import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiGroupsData } from "../../context/groupContext";
import { useApiPersonsData } from "../../context/personsContext";
import { get } from "../../faceit-api/FaceitApi";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import FaceCard from "../face/FaceCard";
import FaceEffect from "./FaceEffect";
import { isFaceModalOrPopupOpen } from "./LiveView";
import "./LiveViewIdentifications.css";

function LiveViewIdentifications(props) {
  const { selectedCameras, events } = props;
  const [mergeIdentifications, setMergeIdentifications] = useState(
    window.sessionStorage.getItem("mergeIdentifications") !== "false"
  );
  const { data: groups } = useApiGroupsData();
  const { data: cameras } = useApiCamerasData();
  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();

  const [isFetchingFaces, setIsFetchingFaces] = useState(false);
  const [faces, setFaces] = useState([]);
  const [showEffect, setShowEffect] = useState(false);
  const [lastShowEffect, setLastShowEffect] = useState(new Date());
  const changeMergeIdentifications = (e) => {
    window.sessionStorage.setItem("mergeIdentifications", e.target.checked);
    setMergeIdentifications(e.target.checked);
  };

  const persons_ids = persons.map((p) => p.id);

  useEffect(() => {
    if (!mergeIdentifications) return;
    if (selectedCameras.length === 0) return;
    setIsFetchingFaces(true);
    get(
      `persons/last_events?page_size=30&camera_id=${selectedCameras
        .map((c) => c.value)
        .join(",")}`
    )
      .then((resp) => {
        if (resp.results) {
          setFaces(resp.results);
        }
      })
      .finally(() => setIsFetchingFaces(false));
  }, [mergeIdentifications, selectedCameras]);

  useEffect(() => {
    if (mergeIdentifications) return;
    if (groups.length === 0) return;
    if (selectedCameras.length === 0) return;
    setIsFetchingFaces(true);
    get(
      `faces?page_size=30&details=true&camera_id=${selectedCameras
        .map((c) => c.value)
        .join(",")}&group_id=${groups.map((g) => g.id).join(",")}`
    ).then((resp) => {
      setIsFetchingFaces(false);
      if (resp.results) {
        setFaces(resp.results);
      }
    });
  }, [selectedCameras, groups, mergeIdentifications]);

  useEffect(() => {
    if (events.length === 0) return;
    if (isFaceModalOrPopupOpen()) return;
    if (!mergeIdentifications) {
      setFaces((state) => [...events, ...state]);
    } else {
      // get all people ids without duplicates
      const newPersonIds = [...new Set(events.map((r) => r.person_id))];
      // ensure the results are sorted from latest
      const resultsSortedByCreated = events.sort(
        (a, b) => new Date(b.create_at) - new Date(a.create_at)
      );
      // find first instance of every person
      var allLatestsInstances = [];
      newPersonIds.forEach((pid) => {
        allLatestsInstances.push(
          resultsSortedByCreated.find((f) => f.person_id === pid)
        );
      });

      setFaces((state) =>
        [
          ...state.filter((face) => !newPersonIds.includes(face.person_id)), // remove exsitings
          ...allLatestsInstances,
        ] // appends updates
          .sort((a, b) => new Date(b.create_at) - new Date(a.create_at))
      ); //sort
    }
    if (!isFaceModalOrPopupOpen()) {
      setLastShowEffect(new Date());
      setShowEffect(true);
    }
  }, [events, mergeIdentifications]);

  return (
    <div
      className="p-3 mt-2 mt-lg-0"
      style={{
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
        minHeight: "100vh",
      }}
    >
      <div className=" d-flex justify-content-between align-items-center px-2">
        <div className="fw-bold">Identify people</div>
        <div className="d-flex align-items-center">
          <span className="me-2" style={{ fontSize: "14px" }}>
            Merge results
          </span>
          <SwitchCheckbox
            checked={mergeIdentifications}
            onChange={changeMergeIdentifications}
          />
        </div>
      </div>
      <div
        className="row mt-2 d-flex justify-content-center"
        key={faces.length}
      >
        {((!mergeIdentifications && isFetchingFaces) ||
          (mergeIdentifications && isFetchingPersons)) && (
          <FaSpinner className="fa-spin my-2 fs-5" />
        )}
        {faces
          .filter((f) => persons_ids.includes(f.person_id))
          .sort((a, b) => new Date(b.create_at) - new Date(a.create_at))
          .map((face) => (
            <div
              key={`identify-face-${face.image}`}
              className="col mb-2"
              style={{ maxWidth: "220px" }}
            >
              <FaceCard
                face={face}
                person={persons.find((p) => p.id === face.person_id) || {}}
                camera={cameras.find((c) => c.id === face.camera_id) || {}}
                group={groups.find((g) => g.id === face.group_id) || {}}
              />
            </div>
          ))}
        {showEffect &&
          !isFaceModalOrPopupOpen() &&
          new Date() - lastShowEffect > 400 && (
            <div>
              <FaceEffect
                image={faces[0] ? faces[0].image : ""}
                person={persons.find((p) => p.id === faces[0]?.person_id)}
                group={groups.find((g) => g.id === faces[0]?.group_id)}
                setShowEffect={setShowEffect}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default LiveViewIdentifications;
