import React, { useContext, useEffect } from "react";
import { DataContext } from "../../App";
import JSMpeg from "@cycjimmy/jsmpeg-player";

function StreamPlayer(props) {
  const { width, height, camera } = props;
  const { webSettings } = useContext(DataContext);
  const elementId = `sr_rtsp_video_${camera.id}`;

  useEffect(() => {
    if (!camera || !camera.rtsp_stream_enabled) return;
    const runPlayer = (rtsp_port, camera_hybrid_host) => {
      var host = window.location.host;
      if (webSettings.is_hybrid) {
        host = camera_hybrid_host; //"wss://faceit.spotreality.com/rtsp-proxy-hybrid/" + camera_id + "and" + TENANT_ID + "/";
      }
      if (!rtsp_port) {
        rtsp_port = 8080 + parseInt(camera.id);
      }
      const websocket =
        "wss://" + host + "/rtsp-proxy/" + camera.id + "/" + rtsp_port + "/";

      new JSMpeg.Player(websocket, {
        canvas: document.getElementById(elementId),
      });

      // var jsmpeg = require("jsmpeg");

      // var player = new jsmpeg(websocket, {
      //   canvas: document.getElementById(elementId),
      // });
      //   player.source.socket.onerror = function (error) {
      //     console.log("failed to connect -> " + error);
      //     if (!reload) {
      //       //   retry_rtsp();
      //       setReload(true);
      //     } else {
      //       var trust_btn = '';
      //       if (settings.is_hybrid && camera_hybrid_host && camera_hybrid_host !== 'localhost') {
      //           trust_btn = `<div className="sr-red-color p-1">The host need to be added to the trusted list</div>
      //               <a className="btn btn-outline-dark sr-red-color" href="https://${camera_hybrid_host}" target="_blank">Trust Host</a>`
      //       }
      //       $('#' + elementId).replaceWith(`
      //               <div style="width: 367px; height: 247px; border-radius: 4px; background-color: #d4d4d4">
      //               <h4 className="sr-red-color pt-3">There is a problem connecting to live stream</h4>
      //               <button className="btn btn-outline-dark sr-red-color" onclick="retry_rtsp_reload()">Retry</button>
      //                ${trust_btn}
      //               <div>
      //       `);
      //     }
      //   };

      // player.source.socket.onclose = function (closeEvent) {
      //   console.log(
      //     "Socket close: -> " + closeEvent.code + " -- id -- " + camera.id
      //   );
      // };
    };

    try {
      runPlayer(camera.proxy_port, camera.hybrid_host);
    } catch (err) {
      console.log("Failed in stream connection", err);
    }
  }, [elementId, webSettings, camera]);

  return (
    <canvas
      id={elementId}
      className="sr_rtsp_video"
      style={{
        width: width,
        height: height,
        borderRadius: "4px",
        backgroundColor: "black",
      }}
    ></canvas>
  );
}

export default StreamPlayer;
