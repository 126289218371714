import { Flex } from "../../elements/flex/Flex";
import { SwitchCheckbox } from "../../elements/switch/SwitchCheckbox";

export const PredictionsCheckBox = (props) => {
  const { title, filters, setFilters } = props;
  return (
    <Flex align="center" gap="8px">
      <SwitchCheckbox
        checked={filters?.camera_id.includes("api_predicted")}
        onChange={(e) =>
          e.target.checked
            ? setFilters({
                ...filters,
                camera_id: ["api_predicted"],
              })
            : setFilters((oldFilters) => {
                const newFilters = { ...oldFilters };
                newFilters.camera_id = newFilters?.camera_id.filter(
                  (id) => id !== "api_predicted"
                );
                delete newFilters.events_only;
                return newFilters || {};
              })
        }
      />
      <label>{title}</label>
    </Flex>
  );
};
