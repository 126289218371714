import React, { useContext, useState } from "react";
import { DataContext } from "../../App";
import { COLORS } from "../../assets/colors";
import MainContainer from "../main-menu/MainContainer";
import Zone from "./Zone";
import IconReload from "../../assets/icons/reload.svg";
import { get } from "../../faceit-api/FaceitApi";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { FaSpinner, FaTabletAlt } from "react-icons/fa";
import Swal from "sweetalert2/src/sweetalert2.js";
import {
  useApiCamerasData,
  useApiCreateCamera,
} from "../../context/camerasContext";
import { useIsMobile } from "../../utils/hooks";

function MainCameras() {
  const { webSettings, userInfo } = useContext(DataContext);
  const isMobile = useIsMobile();
  const { data: cameras } = useApiCamerasData();
  const [isReloading, setIsReloading] = useState(false);
  const { mutate: createCamera } = useApiCreateCamera();

  const reloadStreams = () => {
    setIsReloading(true);
    get("cameras/reload_rtsp")
      .then((resp) => {
        if (resp.status === 1) {
          window.location.reload();
        } else {
          showErrorDialog(resp.message || "Failed to reload cameras streams");
        }
      })
      .finally(() => setIsReloading(false));
  };

  function addAndroidDevice() {
    Swal.fire({
      title: "Add New Android",
      showCancelButton: true,
      confirmButtonColor: COLORS.primary,
      confirmButtonText: "CREATE",
      cancelButtonText: "CANCEL",
      input: "text",
      inputPlaceholder: "Enter name",
    }).then((result) => {
      if (result.value) {
        createCamera({
          cameraData: { name: result.value, is_android: true },
          onSuccessCallback: () =>
            showSuccessDialog("Android device added successfully"),
          onErrorCallback: (resp) =>
            showErrorDialog(resp.errmsg || "Failed to add android device"),
        });
      }
    });
  }

  return (
    <MainContainer>
      <div
        style={{
          color: COLORS.FontGray,
        }}
      >
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div
            style={{
              fontSize: "28px",
              lineHeight: "38px",
            }}
          >
            Cameras
          </div>
          <div className="">
            <button
              className="sr-btn d-flex align-items-center  justify-content-center fw-bold"
              onClick={reloadStreams}
            >
              {!isMobile &&
                (isReloading ? (
                  <FaSpinner className="fa-spin me-2" />
                ) : (
                  <img
                    src={IconReload}
                    height="18px"
                    width={"18px"}
                    alt="reload"
                    className="me-2"
                  />
                ))}
              Reload Cameras stream
            </button>
          </div>
        </div>

        <div className="row d-flex justify-content-around">
          {[...new Set(cameras.map((c) => c.zone))].map((zone) => (
            <div key={zone} className="w-100 mb-3">
              <Zone
                zone={zone}
                cameras={cameras.filter((c) => c.zone === zone)}
              />
            </div>
          ))}
        </div>
      </div>

      {userInfo.is_staff && webSettings.all_cameras_android && (
        <div className="d-flex justify-content-end ">
          <button
            className="sr-btn d-flex align-items-center fw-bold"
            style={{ width: "fit-content" }}
            onClick={addAndroidDevice}
          >
            <FaTabletAlt className="me-2" />
            Add Android Device
          </button>
        </div>
      )}
    </MainContainer>
  );
}

export default MainCameras;
