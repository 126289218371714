import React from "react";
import { NavDropdown } from "react-bootstrap";
import Swal from "sweetalert2/src/sweetalert2.js";
import { COLORS } from "../assets/colors";
import ReactDOMServer from "react-dom/server";
import { put } from "../faceit-api/FaceitApi";
import { showErrorDialog, showSuccessDialog } from "../popups/opretaions";
import { logout } from "./main-menu/Sidebar";
import { FaSignOutAlt } from "react-icons/fa";
import { isWeakPassword } from "../utils/helper";

function UserMenu(props) {
  const { userInfo, close } = props;

  const changePassword = () => {
    Swal.fire({
      title: "Change your password",
      showCancelButton: true,
      confirmButtonText: "Change password",
      html: ReactDOMServer.renderToString(
        <div>
          <label className="swal2-label">Enter old password</label>
          <input
            id="old-password"
            type="password"
            className="swal2-input  mt-1 mb-2"
          />
          <label className="swal2-label">Enter new password</label>
          <input
            type="password"
            id="new-password"
            className="swal2-input  mt-1 mb-2"
          />
          <label className="swal2-label">Re-enter new password</label>
          <input
            type="password"
            id="new-password2"
            className="swal2-input  mt-1 mb-2"
          />
        </div>
      ),
      preConfirm: () => {
        if (
          document.getElementById("new-password").value !==
          document.getElementById("new-password2").value
        ) {
          Swal.showValidationMessage(
            `The new password does not match the validation`
          );
          return false;
        }
        if (isWeakPassword(document.getElementById("new-password").value)) {
          Swal.showValidationMessage(`Weak password.<br>
          The password is considered strong if:<br>
              8 characters length or more<br>
              1 digit or more<br>
              1 uppercase letter or more<br>
              1 lowercase letter or more<br>`);
          return false;
        }
        return true;
      },
    }).then((result) => {
      if (result.value) {
        put("me/change_password", {
          old_password: document.getElementById("old-password").value,
          new_password: document.getElementById("new-password").value,
          new_password2: document.getElementById("new-password2").value,
        }).then((resp) => {
          if (resp.status === 1) {
            showSuccessDialog("Password changed successfully").then(() => {
              close();
              logout();
            });
          } else {
            showErrorDialog(resp.message || "Failed to change password");
          }
        });
      }
    });
  };

  return (
    <div
      style={{
        color: COLORS.FontGray,
        backgroundColor: "#2b2b2b",
      }}
    >
      <NavDropdown.Item className=" me-3 disabled-menu-item" disabled>
        Organization: {userInfo.tenant_name}
      </NavDropdown.Item>
      <NavDropdown.Item className=" me-3 disabled-menu-item" disabled>
        Username: {userInfo.username}
      </NavDropdown.Item>
      <NavDropdown.Item className=" me-3 disabled-menu-item" disabled>
        Version: {userInfo.eye_version}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        className=" me-3 small-menu-item"
        onClick={() => changePassword()}
        style={{
          color: COLORS.FontGray,
          backgroundColor: "#2b2b2b",
        }}
      >
        Change password
      </NavDropdown.Item>

      <NavDropdown.Item
        className=" me-3 small-menu-item d-flex align-items-center"
        onClick={logout}
      >
        <FaSignOutAlt className="me-2" /> Logout
      </NavDropdown.Item>
    </div>
  );
}

export default UserMenu;
