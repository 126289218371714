import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaSpinner, FaUsers } from "react-icons/fa";
import { DataContext } from "../../App";
import { COLORS } from "../../assets/colors";
import PeopleCreateGroupPopup from "./PeopleCreateGroupPopup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import GroupCard from "./GroupCard";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiGroupsData } from "../../context/groupContext";
import { SecondaryButton }  from "../elements/buttons/SecondaryButton";
import { useIsMobile } from "../../utils/hooks";

function Groups() {
  const { userInfo } = useContext(DataContext);
  const isMobile = useIsMobile();
  const { isFetching: isFetchingPersons } = useApiPersonsData();
  const { data: groups } = useApiGroupsData();
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [showGroups, setShowGroups] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    setShowGroups(groups);
    setTotalRows(groups.length);
  }, [groups]);

  const groupsOptions = groups.map((g) => {
    return {
      label: g.name,
      value: g.id,
    };
  });

  const onFilter = (selectedGroups) => {
    var groupsToSet = [...groups];

    if (selectedGroups.length > 0) {
      groupsToSet = groupsToSet.filter((p) => selectedGroups.includes(p.id));
    }
    setTotalRows(groupsToSet.length);
    setShowGroups(groupsToSet);
  };

  const animatedComponents = makeAnimated();

  return (
    <div className="mt-4" style={{ padding: "20px" }}>
      <Modal
        show={showAddGroupModal}
        onHide={() => setShowAddGroupModal(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
        fullscreen={"md-down"}
      >
        <PeopleCreateGroupPopup close={() => setShowAddGroupModal(false)} />
      </Modal>
      <div
        className={`d-flex flex-wrap   ${
          isMobile
            ? "flex-column align-items-start"
            : "align-items-center justify-content-between"
        } gap-8 mb-3`}
        style={{ color: COLORS.FontGray }}
      >
        <div
          className={`d-flex justify-content-center ${isMobile ? "w-100" : ""}`}
        >
          <SecondaryButton
            className={`text-uppercase ${isMobile ? "w-100" : ""}`}
            onClick={() => setShowAddGroupModal(true)}
            disabled={!userInfo.is_staff}
          >
            <FaUsers className="me-2" />
            Add group
          </SecondaryButton>
        </div>

        <div
          className={`d-flex align-items-center gap-2 ${
            isMobile ? "w-100 justify-content-between" : ""
          }`}
        >
          <div
            className="text-truncate"
            style={{ color: COLORS.Gray50, fontSize: "14px" }}
          >
            Filter groups:
          </div>
          <div style={{ minWidth: "200px", maxWidth: "300px" }}>
            <Select
              className="react-select-container w-100"
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={groupsOptions}
              onChange={(options) => {
                onFilter(options.map((o) => o.value.trim()));
              }}
              theme="dark"
            />
          </div>
        </div>
        <div
          className="d-flex gap-2 justify-content-center"
          style={{
            fontSize: "14px",
          }}
        >
          <span>Results count:</span>
          <strong>{totalRows}</strong>
        </div>
      </div>
      <hr style={{ height: "2px", color: COLORS.FontGray }} />
      {isFetchingPersons && (
        <div className="text-center mt-2">
          <FaSpinner
            style={{ color: COLORS.Gray50 }}
            className="fa-spin mb-2 fs-3"
          />
        </div>
      )}

      <div>
        {showGroups.map((group) => (
          <div key={group.id}>
            <GroupCard group={group} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Groups;
