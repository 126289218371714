import { Button, Dialog, Popover, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FaChevronDown, FaChevronRight, FaTrash } from "react-icons/fa";
import { RiFilterOffLine } from "react-icons/ri";
import { DataContext } from "../../App";
import { COLORS } from "../../assets/colors";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiGroupsData } from "../../context/groupContext";
import DeleteFaces from "./DeleteFaces";
import { FilterCheckboxElement } from "./filters/FilterCheckboxElement";
import { FilterSelectElement } from "./filters/FilterSelectElement";
import { FilterTextElement } from "./filters/FilterTextElement";
import { PredictionsCheckBox } from "./filters/PredictionsCheckBox";
import { Flex } from "../elements/flex/Flex";

const spoofOptions = [
  {
    label: "Fraud attempted",
    value: "lock",
  },
  {
    label: "Suspicion",
    value: "suspicion",
  },
  {
    label: "Real face",
    value: "unknown",
  },
];

const userActionOptions = [
  {
    label: "Press Enter",
    value: "clicked_enter",
  },
  {
    label: "Press Exit",
    value: "clicked_exit",
  },
  {
    label: "Press Cancel",
    value: "clicked_cancel",
  },
];

function FacesFilters(props) {
  const { filters, setFilters, setSearchParams, totalResults } = props;
  const { userInfo } = useContext(DataContext);
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);
  // const [isGridView, setIsGridView] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const { data: groups } = useApiGroupsData();
  const { data: cameras } = useApiCamerasData();

  const groupsOptions = groups.map((g) => {
    return { label: g.name, value: g.id };
  });
  const camerasOptions = cameras.map((c) => {
    return { label: c.name, value: c.id };
  });

  const onApplyFilters = useCallback(() => {
    let tempFilters = { ...filters };

    if (tempFilters.group_id && tempFilters.group_id !== "null") {
      tempFilters.group_id = tempFilters.group_id.join(",");
    }
    if (tempFilters.camera_id && tempFilters.camera_id !== "null") {
      tempFilters.camera_id = tempFilters.camera_id.join(",");
    }
    if (tempFilters.spoof && tempFilters.spoof !== "null") {
      tempFilters.spoof = tempFilters.spoof.join(",");
    }
    if (tempFilters.user_action && tempFilters.user_action !== "null") {
      tempFilters.user_action = tempFilters.user_action.join(",");
    }
    setSearchParams(tempFilters);
  }, [setSearchParams, filters]);

  useEffect(onApplyFilters, [onApplyFilters, filters]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onApplyFilters();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onApplyFilters]);

  return (
    <Flex column gap="16px" padding="24px">
      <Flex align="end" gap="16px" flexWrap justify="start">
        <FilterTextElement
          title="Person Name"
          type="text"
          name="name"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterTextElement
          title="Min Confidence"
          type="number"
          name="conf"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterSelectElement
          title="Cameras"
          options={camerasOptions}
          name="camera_id"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterSelectElement
          title="Groups"
          options={groupsOptions}
          name="group_id"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterTextElement
          title="Created After"
          type="datetime-local"
          name="create_at_after"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterTextElement
          title="Created Before"
          type="datetime-local"
          name="create_at_before"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterCheckboxElement
          title="Identification only"
          name="identification_only"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterCheckboxElement
          title="First Seen Only"
          name="first_seen"
          type="checkbox"
          filters={filters}
          setFilters={setFilters}
        />
        {/* <FilterCheckboxElement
          title="Involuntary events"
          name="involuntary"
          type="checkbox"
          filters={filters}
          setFilters={setFilters}
        /> */}
      </Flex>
      <Flex align="center" justify="between" flexWrap>
        <Flex align="center" gap="8px">
          <Flex
            align="center"
            gap="4px"
            className="pointer"
            onClick={() => setAdvancedFiltersOpen(!advancedFiltersOpen)}
            style={{
              fontSize: "14px",
            }}
          >
            <span className="me-2" style={{ textDecoration: "underline" }}>
              Advanced filters
            </span>
            {advancedFiltersOpen ? <FaChevronDown /> : <FaChevronRight />}
          </Flex>
          <Button
            onClick={() => setFilters({})}
            startIcon={<RiFilterOffLine />}
          >
            Clear filters
          </Button>
        </Flex>
        <div>
          <span>Total Results:</span>
          <span className="mx-2">{totalResults}</span>
          {totalResults > 0 && userInfo.is_staff && (
            <FaChevronDown
              className="pointer"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              style={{ fontSize: "11px" }}
            />
          )}
          <Popover
            id={Boolean(anchorEl) ? "simple-popper" : undefined}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 1 }}>
              <Button
                className="d-flex align-items-center"
                style={{ color: COLORS.red }}
                startIcon={<FaTrash />}
                onClick={() => setOpenDeletePopup(true)}
              >
                <span>
                  Delete all <strong>{totalResults}</strong> events
                </span>
              </Button>
            </Typography>
          </Popover>
          <Dialog
            open={openDeletePopup}
            onClose={() => setOpenDeletePopup(false)}
          >
            <DeleteFaces
              filters={filters}
              totalEvents={totalResults}
              close={() => setOpenDeletePopup(false)}
            />
          </Dialog>
        </div>
      </Flex>
      {advancedFiltersOpen && (
        <Flex align="end" gap="16px" flexWrap>
          <FilterSelectElement
            title="Fraud alerts"
            options={spoofOptions}
            name="spoof"
            filters={filters}
            setFilters={setFilters}
          />
          <FilterSelectElement
            title="User action"
            options={userActionOptions}
            name="user_action"
            filters={filters}
            setFilters={setFilters}
          />
          <PredictionsCheckBox
            title="Manual predictions"
            filters={filters}
            setFilters={setFilters}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default FacesFilters;
