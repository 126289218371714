import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import FaceCard from "../face/FaceCard";
import { Box, CircularProgress } from "@mui/material";
import { useApiFacesFilteredPagination } from "../../context/facesContext";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useApiGroupsData } from "../../context/groupContext";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiCamerasData } from "../../context/camerasContext";
import { COLORS } from "../../assets/colors";
import { Flex } from "../elements/flex/Flex";
import { HiChevronDoubleDown } from "react-icons/hi";
import {BlackButton} from "../elements/buttons/BlackButton";

const PAGE_SIZE = 50;
const SORT_BY = "-create_at";

function FacesTrackingModal({ show, handleClose, trackingId }) {
  const [filters, setFilters] = useState({ tracking_id: trackingId });
  const [totalResults, setTotalResults] = useState(0);

  const { data: groups } = useApiGroupsData();
  const { data: persons } = useApiPersonsData();
  const { data: cameras } = useApiCamerasData();

  const {
    data: facesData,
    isFetching: isFetchingFaces,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useApiFacesFilteredPagination(
    PAGE_SIZE,
    filters,
    SORT_BY,
    [], // assuming persons are not needed for this query
    show, // enabled only when modal is shown
    true, // attach details
    true // noFilterBuild to use filters as is
  );

  const getFacesList = () => {
    return facesData?.pages?.map((page) => page?.results || []).flat() || [];
  };

  useEffect(() => {
    setTotalResults(
      facesData?.pages?.length ? facesData?.pages[0]?.count : 0 || 0
    );
  }, [facesData]);

  useEffect(() => {
    if (trackingId) {
      setFilters({ tracking_id: trackingId });
    }
  }, [trackingId]);

  const isNotTheSameTrackingId = () => {
    const lst = getFacesList();
    return lst.length && lst[0].tracking_id !== trackingId;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      // Change this to your desired background color
    >
      <Flex
        column
        gap="24px"
        style={{
          borderRadius: "8px",
          backgroundColor: COLORS.Gray700,
        }}
      >
        <Flex
          align="center"
          justify="between"
          padding="8px 16px"
          style={{
            borderRadius: "8px 8px 0 0",
            backgroundColor: COLORS.Gray800,
          }}
        >
          <Flex column>
            <Modal.Title style={{ color: COLORS.FontGray }}>
              Tracking Results: {totalResults} Faces
            </Modal.Title>
            <label
              style={{
                color: COLORS.Gray100,
                fontSize: "12px",
              }}
            >
              Tracking ID: {trackingId}
            </label>
          </Flex>
          <FaTimes
            className="fs-3 pointer"
            color={COLORS.FontGray}
            onClick={handleClose}
          />
        </Flex>
        <Flex column gap="16px">
          {(isFetchingFaces && !fetchNextPage) || isNotTheSameTrackingId() ? (
            <Box className="text-center fs-4 my-2">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Flex gap="8px" flexWrap justify="around">
              {getFacesList().map((face) => (
                <FaceCard
                  key={`face-${face.image}`}
                  face={face || {}}
                  person={persons.find((p) => p.id === face.predicted_id) || {}}
                  camera={cameras.find((c) => c.id === face.camera_id) || {}}
                  group={groups.find((g) => g.id === face.group_id) || {}}
                  isUnknown={face.person_id === "N/A" || face.person_id === ""}
                />
              ))}
            </Flex>
          )}
          {hasNextPage && (
            <Flex justify="center">
              <Button
                onClick={fetchNextPage}
                startIcon={
                  isFetchingNextPage ? (
                    <FaSpinner className="fa-spin" />
                  ) : (
                    <HiChevronDoubleDown />
                  )
                }
                disabled={isFetchingNextPage}
              >
                Load more
              </Button>
            </Flex>
          )}
          <Flex w100 justify="end" padding="16px">
            <BlackButton onClick={handleClose}>Close</BlackButton>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default FacesTrackingModal;
