import React, { useState } from "react";
import { IMAGE_BASE_PATH, put } from "../../faceit-api/FaceitApi";
import IconUser from "../../assets/icons/user.svg";
import { FaCloudUploadAlt, FaSave, FaTimes } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";

function ChangeAvatarPopup(props) {
  const { userInfo, close } = props;
  const [newAvatar, setNewAvatar] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = () => {
      var block = reader.result.split(";");
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      setNewAvatar(realData);
    };
    reader.onerror = (error) => console.error(error);
    reader.readAsDataURL(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const saveNewAvatar = () => {
    put(`me/avatar`, { avatar: newAvatar }).then((resp) => {
      console.log(resp);
      if (resp.status === 1) {
        showSuccessDialog("Profile image updated").then(() =>
          window.location.reload()
        );
      } else {
        showErrorDialog("Failed to update profile image");
      }
    });
  };

  return (
    <div
      className="p-2"
      style={{
        color: COLORS.FontGray,
      }}
    >
      <div className="d-flex ">
        <span
          className="text-end"
          onClick={close}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "20px",
          }}
        >
          <FaTimes className="fs-4" />
        </span>
        <span
          className="m-auto mt-4 mt-md-2 mb-3"
          style={{
            fontSize: "24px",
            lineHeight: "33px",
          }}
        >
          Change profile image
        </span>
      </div>

      <div className="text-center">
        <img
          src={
            preview
              ? preview
              : userInfo.avatar
              ? `${IMAGE_BASE_PATH}${userInfo.avatar}`
              : IconUser
          }
          style={{
            borderRadius: "12px",
            maxHeight: "150px",
            maxWidth: "150px",
          }}
          //   height={"150"}
          //   width={"150"}
          alt="user-avater"
        />
      </div>
      <div className="text-center mt-3">
        <label className="custom-file-upload">
          <input
            type="file"
            multiple="false"
            onChange={handleImageChange}
            accept="image/*"
          />
          <FaCloudUploadAlt className="me-md-3 d-none d-md-inline" />
          Select image file
        </label>
        {preview && (
          <div className="mt-2 w-50 mx-auto">
            <button
              className="sr-btn  d-flex align-items-center justify-content-center"
              onClick={saveNewAvatar}
            >
              <FaSave className="me-md-3 d-none d-md-block" />
              SAVE
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChangeAvatarPopup;
