import { Flex } from "../../elements/flex/Flex";
import { SwitchCheckbox } from "../../elements/switch/SwitchCheckbox";

export const FilterCheckboxElement = (props) => {
  const { title, name, filters, setFilters, classes } = props;

  return (
    <Flex align="center" className={classes}>
      <SwitchCheckbox
        className="me-2"
        checked={filters[name] === "true"}
        onChange={(e) =>
          e.target.checked
            ? setFilters({
                ...filters,
                [name]: "true",
              })
            : setFilters((oldFilters) => {
                const newFilters = { ...oldFilters };
                delete newFilters[name];
                return newFilters || {};
              })
        }
      />
      <label>{title}</label>
    </Flex>
  );
};
