import React, { useContext, useState } from "react";
import {
  BsCameraVideo,
  BsClock,
  BsPeople,
  BsPercent,
  BsPersonBadgeFill,
} from "react-icons/bs";
import { ImArrowRight } from "react-icons/im";
import { FaRegPlayCircle, FaTimes } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import { dateDisplay } from "../../utils/helper";
import { playVideoClip } from "../../popups/opretaions";
import HistorySection from "./HistorySection";
import AddFaceToExistingPerson from "./AddFaceToExsitingPerson";
import AddFaceToNewPerson from "./AddFaceToNewPerson";
import DetailLine from "./DetailLine";
import { DataContext } from "../../App";
import { useIsMobile } from "../../utils/hooks";
import { FaceImage } from "./FaceImage";

function FaceRecognizedModal(props) {
  const { close, face, person, camera, group, isUnknown } = props;
  const isMobile = useIsMobile();
  const [showAddExisting, setShowAddExisting] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const { userInfo } = useContext(DataContext);

  const faceExtra = {
    video: face.video ? face.video : face.face_extra?.video,
  };

  return (
    <div
      className="h-100 face-recognized-modal"
      style={{
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
        overflowY: "auto",
      }}
    >
      <div
        className="d-flex p-3 justify-content-between align-items-center w-100 "
        style={{
          backgroundColor: COLORS.Gray800,
          borderRadius: "8px 8px 0 0",
        }}
      >
        <div className="d-flex align-items-center">
          <BsPersonBadgeFill className="me-1" />
          <span>Face Recognition</span>
        </div>
        <span onClick={close}>
          <FaTimes className="fs-3" />
        </span>
      </div>
      <div className="p-3">
        {isUnknown ? (
          <div
            className="text-capitalize fw-bold fs-5"
            style={{ color: COLORS.orange }}
          >
            UNKNOWN
          </div>
        ) : (
          <div className="text-capitalize fw-bold fs-5">
            {person.person_name?.replaceAll("_", "")}
          </div>
        )}

        <div
          className={`d-flex gap-8 ${
            isMobile ? "flex-column" : "justify-content-between"
          } mt-1`}
        >
          <div className="d-flex gap-2 flex-column">
            <div
              className={`d-flex align-items-center justify-content-${
                isMobile ? "between w-100" : "start"
              } gap-8`}
            >
              <FaceImage
                face={face}
                size={isMobile ? "140px" : "180px"}
                alt="clicked-face"
              />
              {person.faces && <ImArrowRight className="fs-2" />}
              <div>
                {person.faces && (
                  <img
                    src={`${IMAGE_BASE_PATH}${
                      person.faces[0] ? person.faces[0] : ""
                    }`}
                    style={{ borderRadius: "8px" }}
                    alt="clicked-face"
                    height={isMobile ? 140 : 180}
                    width={isMobile ? 140 : 180}
                  />
                )}
              </div>
            </div>
            {isUnknown && (
              <div className={isMobile ? "text-center" : ""}>
                {`Predicted as "${person.person_name}" with ${face.grade}% confidence`}
              </div>
            )}
          </div>
          <div className="d-flex flex-column">
            <DetailLine
              tooltip={dateDisplay(new Date(face.create_at))}
              text={dateDisplay(new Date(face.create_at))}
              icon={<BsClock style={{ color: COLORS.primary }} />}
            />

            <DetailLine
              tooltip={"Camera - " + camera.name}
              text={camera.name}
              icon={<BsCameraVideo style={{ color: COLORS.primary }} />}
            />
            {!isUnknown && (
              <DetailLine
                tooltip={`Confidence: ${parseInt(face.grade)}%`}
                text={`Confidence: ${parseInt(face.grade)}%`}
                icon={<BsPercent style={{ color: COLORS.primary }} />}
              />
            )}
            {!isUnknown && (
              <DetailLine
                tooltip={`Group - ${group.name}`}
                text={`Group: ${group.name}`}
                icon={<BsPeople style={{ color: COLORS.GROUP[group.color] }} />}
                color={COLORS.GROUP[group.color]}
              />
            )}
            {person.description && !isUnknown && (
              <div>
                Description
                <div
                  className="p-2"
                  style={{
                    border: "1px solid",
                    borderColor: COLORS.Gray600,
                    borderRadius: "8px",
                    height: "100px",
                    overflowY: "scroll",
                    fontSize: "12px",
                  }}
                >
                  {person.description}
                </div>
              </div>
            )}
          </div>
          <div
            className={`d-flex align-items-center justify-content-between flex-column gap-2 ${
              isMobile ? "w-100" : ""
            }`}
          >
            <button
              className="sr-btn mx-1 px-1 d-flex align-items-center justify-content-center"
              onClick={() => playVideoClip(faceExtra.video, isMobile)}
              disabled={!faceExtra.video}
              style={{ fontSize: "14px" }}
            >
              <FaRegPlayCircle className="me-2" />
              <span>Watch Video</span>
            </button>
            {userInfo.is_staff && (
              <button
                disabled={showAddExisting}
                className="sr-btn mx-1 px-1"
                onClick={() => setShowAddNew(true)}
                style={{ fontSize: "14px" }}
              >
                Add as a new person
              </button>
            )}
            {userInfo.is_staff && (
              <button
                disabled={showAddNew}
                className="sr-btn mx-1 px-1"
                onClick={() => {
                  setShowAddExisting(true);
                }}
                style={{ fontSize: "14px" }}
              >
                Add to existing person
              </button>
            )}
          </div>
        </div>
        {showAddExisting && (
          <div>
            <AddFaceToExistingPerson
              face={face}
              close={() => setShowAddExisting(false)}
            />
          </div>
        )}
        {showAddNew && (
          <div>
            <AddFaceToNewPerson
              face={face}
              close={() => setShowAddNew(false)}
            />
          </div>
        )}

        <hr className="my-4" />
        <div>
          <HistorySection
            person={person}
            isUnknown={isUnknown}
            initTime={face.create_at}
            face={face}
          />
        </div>
      </div>
    </div>
  );
}

export default FaceRecognizedModal;
