import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { COLORS } from "../../assets/colors";
import MainContainer from "../main-menu/MainContainer";
import FaceCard from "../face/FaceCard";
import { FaFilter, FaSpinner } from "react-icons/fa";
import FacesFilters from "./FacesFilters";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiFacesFilteredPagination } from "../../context/facesContext";
import { Box, CircularProgress } from "@mui/material";
import { useApiGroupsData } from "../../context/groupContext";
import { useInfiniteScroll } from "../hooks/useInfiniteScroll";
import FacesTrackingModal from "./FacesTrackingModal";
import { Flex } from "../elements/flex/Flex";

const FILTER_KEYS = [
  "name",
  "create_at_before",
  "create_at_after",
  "group_id",
  "camera_id",
  "identification_only",
  "conf",
  "spoof",
  "user_action",
  "first_seen",
  // "involuntary",
];
const PAGE_SIZE = 50;
const SORT_BY = "-create_at";

function Faces() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({});
  const [readyToFetchFaces, setReadyToFetchFaces] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [trackingId, setTrackingId] = useState(null);

  const { data: groups } = useApiGroupsData();
  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();
  const { data: cameras, isFetching: isFetchingCameras } = useApiCamerasData();

  const {
    data: facesData,
    isFetching: isFetchingFaces,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useApiFacesFilteredPagination(
    PAGE_SIZE,
    filters,
    SORT_BY,
    persons,
    readyToFetchFaces && !isFetchingPersons && !isFetchingCameras, // enabled
    true // attach details (face extra) to results
  );
  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(
    observerElemForFetchPage,
    hasNextPage || false,
    fetchNextPage || console.log
  );

  const getFacesList = () => {
    return facesData?.pages?.map((page) => page?.results || []).flat() || [];
  };

  useEffect(() => {
    setTotalResults(
      facesData?.pages?.length ? facesData?.pages[0]?.count : 0 || 0
    );
  }, [facesData]);

  useEffect(() => {
    if (!searchParams) return;
    var tempFilters = {};
    FILTER_KEYS.forEach((key) => {
      tempFilters[key] = searchParams.get(key);
    });
    if (tempFilters.group_id && tempFilters.group_id !== "null") {
      tempFilters.group_id = tempFilters.group_id.split(",");
    }
    if (tempFilters.camera_id && tempFilters.camera_id !== "null") {
      tempFilters.camera_id = tempFilters.camera_id.split(",");
    }
    if (tempFilters.spoof && tempFilters.spoof !== "null") {
      tempFilters.spoof = tempFilters.spoof.split(",");
    }
    if (tempFilters.user_action && tempFilters.user_action !== "null") {
      tempFilters.user_action = tempFilters.user_action.split(",");
    }
    // if (tempFilters.involuntary && tempFilters.involuntary === "null") {
    //   tempFilters.involuntary = false;
    // }
    setFilters(tempFilters);

    setReadyToFetchFaces(true);
  }, [searchParams]);

  // Memoize the modal open function
  const openTrackingModal = useCallback((trackingId) => {
    setTrackingId(trackingId);
    setShowTrackingModal(true);
  }, []);

  // Memoize the modal close function
  const closeTrackingModal = useCallback(() => {
    setShowTrackingModal(false);
    setTrackingId(null);
  }, []);
  const isFetching = isFetchingFaces || isFetchingPersons || isFetchingCameras;
  const AppliedFilters = Object.values(filters).filter(
    (v) => !!v && v !== "null"
  ).length;
  return (
    <MainContainer>
      <div style={{ color: COLORS.FontGray }}>
        <div className="d-flex align-items-center justify-content-between">
          <Flex
            gap="8px"
            align="center"
            className="pointer"
            onClick={() => setShowFilters(!showFilters)}
          >
            <Flex
              column
              style={{
                position: "relative",
              }}
            >
              {AppliedFilters > 0 && (
                <Flex
                  align="center"
                  justify="center"
                  gap="8px"
                  title={`Currently applied ${AppliedFilters} filters`}
                  style={{
                    position: "absolute",
                    border: "1px solid #fff",
                    backgroundColor: COLORS.FontGray,
                    color: COLORS.Gray900,
                    borderRadius: "50%",
                    top: "-3px",
                    right: "-3px",
                    width: "12px",
                    height: "12px",
                    fontSize: "9px",
                  }}
                >
                  {AppliedFilters}
                </Flex>
              )}
              <FaFilter color={COLORS.PrimaryLight} />
            </Flex>
            <div>{`${showFilters ? "Hide " : ""}Filters`}</div>
          </Flex>
          {!showFilters && <div>Showing {totalResults} Faces</div>}
        </div>
        {showFilters && (
          <FacesFilters
            filters={filters}
            setFilters={setFilters}
            setSearchParams={setSearchParams}
            totalResults={totalResults}
          />
        )}
        <hr />
        <Flex column justify="around" w100 padding="16px">
          {isFetching && (
            <Box className="text-center fs-4 my-2">
              <CircularProgress color="primary" />
            </Box>
          )}
          {getFacesList()?.length === 0 && !isFetching && (
            <div className="fs-5 my-2 text-center">
              No events were found matching the search
            </div>
          )}
          <Flex gap="16px" justify="around" flexWrap>
            {getFacesList().map((face) => (
              <FaceCard
                key={`face-${face.image}`}
                face={face || {}}
                person={persons.find((p) => p.id === face.predicted_id) || {}}
                camera={cameras.find((c) => c.id === face.camera_id) || {}}
                group={groups.find((g) => g.id === face.group_id) || {}}
                isUnknown={face.person_id === "N/A" || face.person_id === ""}
                openTrackingModal={openTrackingModal}
              />
            ))}
          </Flex>
          <div
            className="loader d-flex justify-content-center"
            ref={observerElemForFetchPage}
          >
            {isFetchingNextPage ? (
              <div className="d-flex flex-column justify-content-center">
                <FaSpinner className="fa-spin" />
              </div>
            ) : (
              ""
            )}
          </div>
        </Flex>
      </div>
      <FacesTrackingModal
        show={showTrackingModal}
        handleClose={closeTrackingModal}
        trackingId={trackingId}
      />
    </MainContainer>
  );
}

export default Faces;
